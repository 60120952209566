import React from 'react';
import 'react-native';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Find from "../components/Map";
import ContactForm from '../components/ContactForm';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainTitle, Welcome, Intro, Overview} from "./PagesElements";
import { FooterLink } from '../components/Footer/FooterStyles';

const Home = () => {
return (
	<>
	<Navbar />
	<MainTitle>
		Independent Cycle Mechanic <br/>
		Cytech Qualified
	</MainTitle>
	<Welcome> 
		We value customer satisfaction and strive to provide excellent service. Our knowledgeable and passionate mechanic will help get your bike fixed or upgraded as quickly as possible with over 22 years experience. 
	</Welcome>
	<Welcome style={{color:"#8B0000"}}>
		Services provided include<br/>
	</Welcome>
	<Intro>
		<Overview>
			<li>Suspension Servicing (Shock, Fork, Seat Posts)</li>
			<li>E-Bike Diagnostics</li>
			<li>Motor Servicing and Repairs</li>
			<li>Wheel Building</li>
			<li>General Services + Routine Work</li>
			<li>Custom/Bespoke Build Options</li>
		</Overview>
	</Intro>
	{/* <Box>
		<Container>
			<Row>
				<Column>
					<img src="https://502101718834-eu-west-1-public-content.s3.eu-west-1.amazonaws.com/standards.jpg" alt="icon" style={{height:75, width:125}}/>
				</Column>
				<Column>
					<img src="https://502101718834-eu-west-1-public-content.s3.eu-west-1.amazonaws.com/north-notts.jpg" alt="icon" style={{height:75, width:75}}/>
				</Column>
				<Column>
					<img src="https://502101718834-eu-west-1-public-content.s3.eu-west-1.amazonaws.com/great-food.jpg" alt="icon" style={{height:75, width:150}}/>
				</Column>
			</Row>
		</Container>
	</Box> */}
	<Welcome style={{color:"#8B0000"}}>
		Find Us In The Workshop
	</Welcome>
	<Intro>
		<Overview>
			6 Ollerton Road <br/>
			Edwinstowe <br/>
			Mansfield <br/>
			NG21 9QG <br/>
		</Overview>
	</Intro>
	<Intro>
	<Find />
	</Intro>
	<Welcome style={{color:"#8B0000"}}>
		Opening Times
	</Welcome>
	<Intro>
		<Overview>
			Monday - Friday 10am - 5pm <br/>
			Saturday 9am - 2pm <br/>
			Sunday Closed <br/>
		</Overview>
	</Intro>
	<Welcome style={{color:"#8B0000"}}>
		Contact Us
	</Welcome>
	<Intro>
		<Overview>
		<FooterLink href = "mailto: dale@jacksoncycleservices.co.uk"> <FontAwesomeIcon icon={faEnvelope} size="2x"/></FooterLink> &emsp; <FooterLink href = "tel: 07513184486"> <FontAwesomeIcon icon={faPhone} size="2x"/></FooterLink> 
		</Overview>
	</Intro>
	<ContactForm />
	<Footer />
	</>
);
};

export default Home;
